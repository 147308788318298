import React from 'react'
import './index.css'
import Dashboardformhelper from '../Dashboardformhelper';
import CreatSiteModal from '../CreatSiteModal';
import Loader from './../../assests/spinner2.gif';
import authcontext  from '../../Store/auth-context';
import  { Navigate } from 'react-router-dom'
function Dashboardform(props){

  const [sitestore,setsitestore] = React.useState([])

  const [selectsiteobject,setselectsiteobject] = React.useState([])

  const [selectsiteid,setselectsiteid] = React.useState()

  const [isLoading,setisLoading] = React.useState(false)

  const [isClicked,setisClicked] = React.useState({
    discover:true,
    mysites:false
  })

  const [iscreatsiteclicked,setcreatesite]= React.useState(false)

  const ctx = React.useContext(authcontext)

  React.useEffect(() => {
    setisLoading(true)
    console.log(isLoading)
    fetch(`https://api.evci.in/rest/v1/site/get-all?st=${ctx}`)
    .then(response => {
      return response.json()
    })
    .then(data => {
      console.log(ctx)
      console.log('it')
      console.log(data);
      if(data.ack=='0'){
    
        // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
        window.location.replace(ctx)
      }
      setsitestore(data.sites);
      setisLoading(false)
    });
   
    console.log(isLoading)
  },[]); 

  function Discoverclickhandler(){
    setisClicked({
      discover:true,
      mysites:false
    })
    setisLoading(true)
    console.log(isLoading)
    fetch(`https://api.evci.in/rest/v1/site/get-all?st=${ctx}`)
    .then(response => {
      return response.json()
    })
    .then(data => {
      if(data.ack=='0'){
    
        window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
      }
      console.log(data.sites);
      setsitestore(data.sites);
      setisLoading(false)
    });
   
    console.log(isLoading)
  }

  function mysitehandler(){
    setisClicked({
      discover:false,
      mysites:true
    })
    setisLoading(true)
    console.log(isLoading)
    console.log(props.getallsitedata.sid)
    fetch("https://api.evci.in/rest/v1/site/get-by-user", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        st:ctx
      })
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if(data.ack=='0'){
    
        window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
      }
      console.log(data.my_sites);
      setsitestore(data.my_sites);
    });
    setisLoading(false)
    console.log(isLoading)
  }


  function setcreatesitehandler(){
    setcreatesite(prev=>!prev)
  }

  function sitidhandler(ourobj){

  //  setselectsiteobject(ourobj)
  // setsitestore(ourobj)
  props.setalldatahandler(ourobj)
    
 console.log(ourobj)
  }
 


  function dashboardsiteclickhandler(){

  }

  const dashboardhelperelements = sitestore.map(function(obj){
    return <Dashboardformhelper key={obj.sid}   sitenamelabel = {obj.name}  selectidhandler = {()=>sitidhandler(obj)}/>
  })
    return(
        <>
         <div className='dashboard--form'>
            <div className='dashboard--label'>
             <h4 className='dashboard-lable-1'>Choose a Site</h4>
             <h5 className='dashboard-label-2' onClick={()=>setcreatesitehandler(true)}>Create a New Site</h5>
            </div>
            {iscreatsiteclicked && <CreatSiteModal setcreatesitehandler={setcreatesitehandler}/>}
            {/* <input type='text' className='dashboard--input' placeholder='Type here to Search Site'/> */}
            <div className='dashboard--button-section'>
              <button className={`dashboard-button ${isClicked.discover?"active":""}`} onClick={Discoverclickhandler}>Discover</button>
              <button className={`dashboard-button ${isClicked.mysites?"active":""}`} onClick={mysitehandler}>My Sites</button>
            </div>
            {/* <div className='dashboard--filtre'>
              <h5 className='dashboard--filtre--label'>Filtre by Alphabet</h5>
              <h5 className='dashboard--filtre--label-button'>A-D</h5>
              <h5 className='dashboard--filtre--label-button'>E-H</h5>
              <h5 className='dashboard--filtre--label-button'>I-L</h5>
              <h5 className='dashboard--filtre--label-button'>M-P</h5>
              <h5 className='dashboard--filtre--label-button'>Q-T</h5>
              <h5 className='dashboard--filtre--label-button'>U-Z</h5>
            </div> */}
            <div className='dashboard-input-city'>
               {isLoading && <img src={Loader} alt="spinner" className='dashboard--loader'/>}
               {sitestore.length>0 && dashboardhelperelements }
               {/* <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/>
               <Dashboardformhelper/> */}
            </div>
            {/* <button className='dashboard-end-button' onClick = {dashboardsiteclickhandler}>Choose</button> */}
         </div>
        </>
    )
}

export default Dashboardform