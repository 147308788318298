import React from "react";
import GoogleMapReact from 'google-map-react';
import bluebuildingLogo from './../../assests/red-location-logo.png';



const AnyReactComponent = ({ text }) => <>
  <div className="pin">{text}</div>
  <img style={{width:'40px'}} src={bluebuildingLogo} alt="icon"/>
  <div className="pulse"></div>
</>;


const Marker = props => {
  return <>
    <div className="pin"></div>
    <div className="pulse"></div>
  </>
}

// [
  // 77.473473,
  // 34.0211074,
  // 77.6839609,
  // 34.174654
// ]

export default function OldGooglemapdisplay(props) {

  const defaultProps = {
    center: {
      lat: 20.5937,
      lng: 78.9629
    },
    zoom: 4,
    bounds:[77.473473,34.0211074,77.6839609,34.174654]
  };

  console.log("old")



  
  return (
    // Important! Always set the container height explicitly
    <div className="Googlemap-display-intial" style={{ height: '60vh', width: '80%', marginLeft: 'auto', marginTop: "10px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDpaaF8c7ArIRCjCQM3rsm0ZAuuR--RAjw" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        deafaultbounds={defaultProps.bounds}
        yesIWantToUseGoogleMapApiInternals
      >

         {
          props.data.map(obj=>{
            return <AnyReactComponent lat={obj.Latitude} lng={obj.Longitude} text={obj.Name} />

          })
         }
      </GoogleMapReact>
    </div>
  );
}





