import React from 'react'
import './index.css'
import authcontext from '../../Store/auth-context';


function AnalysisHelper(props) {
    const [AnalyisisName, setAnalysisName] = React.useState("")
    const [backoff, setbackoff] = React.useState(null)
    const [yearOfAnalysis, setyearofAnaysis] = React.useState([])
    const [Mstate, setMstate] = React.useState([])
    const [hoardinfcost, sethoardingcost] = React.useState(null)
    const [capex2W, setcapex2W] = React.useState(null)
    const [capex3WS, setcapex3WS] = React.useState(null)
    const [capex4WS, setcapex4WS] = React.useState(null)
    const [capex4WF, setcapex4WF] = React.useState(null)
    const [kioskcost, setkiosk] = React.useState(null)
    const [year1conversion, setyear1conversion] = React.useState(null)
    const [year2conversion, setyear2conversion] = React.useState(null)
    const [year3conversion, setyear3conversion] = React.useState(null)
    const [holidaypercentage, setholidaypercentage] = React.useState(null)
    const [fastcharging, setfastcharging] = React.useState(null)
    const [slowcharging, setslowcharging] = React.useState(null)
    const [clusterCheck, setclusterCheck] = React.useState(false)
    const [clusterth, setclusterth] = React.useState(null)
    const [dendogramCheck, setdendogramCheck] = React.useState(false)
    const [usedefaultcheck, setusedefaultcheck] = React.useState(false)
    let [checkresponse, setcheckresponse] = React.useState('')

    const ctx = React.useContext(authcontext)

    function backoffhandler(e) {
        setbackoff(e.target.value)
    }

    function setAnalysisNamehandler(e) {
        setAnalysisName(e.target.value)
    }

    function MhandleCheckboxChange(e) {
        console.log(Mstate)
        if (e.target.checked === true) {
            setMstate([...Mstate, e.target.value]);
        }
        else if (e.target.checked === false) {
            let freshArray = Mstate.filter(val => val !== e.target.value);
            setMstate([...freshArray]);
        }

        console.log(Mstate)
    };

    function yearOfAnalysishandler(e) {
        //setyearofAnaysis
        if (e.target.checked === true) {
            setyearofAnaysis([...yearOfAnalysis, e.target.value]);
        }
        else if (e.target.checked === false) {
            let freshArray = yearOfAnalysis.filter(val => val !== e.target.value);
            setyearofAnaysis([...freshArray]);
        }

    }

    function sethoardingcosthandler(event) {
        sethoardingcost(event.target.value)
    }

    function setcapex2Whandler(event) {
        setcapex2W(event.target.value)
    }
    function setcapex3WShandler(event) {
        setcapex3WS(event.target.value)
    }
    function setcapex4WShandler(event) {
        setcapex4WS(event.target.value)
    }
    function setcapex4WFhandler(event) {
        setcapex4WF(event.target.value)
    }

    function setkioskhandler(event) {
        setkiosk(event.target.value)
    }


    function setyear1conversionhandler(event) {
        setyear1conversion(event.target.value)
    }

    function setyear2conversionhandler(event) {
        setyear2conversion(event.target.value)
    }
    function setyear3conversionhandler(event) {
        setyear3conversion(event.target.value)
    }


    function setholidaypercentagehandler(event) {
        setholidaypercentage(event.target.value)
    }

    function setfastcharginghandler(event) {
        setfastcharging(event.target.value)
    }

    function setslowcharginghandler(event) {
        setslowcharging(event.target.value)
    }

    function setclusterCheckhandler(event) {
        setclusterCheck(event.target.checked)
    }

    function setclusterthhandler(event) {
        setclusterth(event.target.value)
    }

    function setdendogramCheckhandler(event) {
        setclusterCheck(event.target.checked)
    }

    function setusedefaultcheckhandler(event) {
        setclusterCheck(event.target.checked)
    }

    function Analysisformhandler(event) {
        event.preventDefault();

        const ourdata = {
            backoff,
            yearOfAnalysis,
            Mstate,
            hoardinfcost,
            capex2W,
            capex3WS,
            capex4WS,
            capex4WF,
            kioskcost,
            year1conversion,
            year2conversion,
            year3conversion,
            holidaypercentage,
            fastcharging,
            slowcharging,
            clusterCheck,
            clusterth,
            dendogramCheck,
            usedefaultcheck
        }


        const yearOfAnalysisininteger = yearOfAnalysis.map((val) => {
            return parseInt(val)
        })

        console.log(ourdata)
        console.log(props.getallsitedata)
        fetch('https://api.evci.in/rest/v1/analysis/start', {
            method: 'POST',
            body: JSON.stringify({
                name: AnalyisisName,
                sid: props.getallsitedata.sid,
                st: ctx,
                data: {
                    backoff_factor: parseFloat(backoff),
                    M: Mstate,
                    years_of_analysis: yearOfAnalysisininteger,
                    // capex_2W: parseFloat(capex2W),
                    capex_3WS: parseFloat(capex3WS),
                    capex_4WS: parseFloat(capex4WF),
                    capex_4WF: parseFloat(capex4WF),
                    "hoarding cost": parseFloat(hoardinfcost),
                    kiosk_cost: parseFloat(kioskcost),
                    year1_conversion: parseFloat(year1conversion),
                    year2_conversion: parseFloat(year2conversion),
                    year3_conversion: parseFloat(year3conversion),
                    holiday_percentage: parseFloat(holidaypercentage),
                    fast_charging: parseFloat(fastcharging),
                    slow_charging: parseFloat(slowcharging),
                    cluster: clusterCheck,
                    cluster_th: parseFloat(clusterth),
                    plot_dendrogram: dendogramCheck,
                    use_defaults: usedefaultcheck
                }
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if (data.ack == '0') {

                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                }

                console.log(data)
                window.location.reload(false);
                // let intervalID = setInterval(()=>responserhandler(data.aid), 5000)
                // console.log(intervalID,'success')
                // if (checkresponse=='success') {
                //     clearInterval(intervalID); // Stop the interval if the condition holds true
                // }

            }).catch(error => console.error('Error:', error));
        props.setNewAnalysisClicked(false)

    }

    function responserhandler(rid) {


        fetch('https://api.evci.in/rest/v1/analysis/get-response', {
            method: 'POST',
            body: JSON.stringify({
                st: ctx,
                aid: rid
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                console.log(data.response.status)
                checkresponse = data.response.status
                //   setcheckresponse(data.response.status)
                if (checkresponse == "success") {
                    alert('done')
                }
                console.log(checkresponse)
            }).catch(error => console.error('Error:', error));
    }

    return (
        <div className='Analysis-Menu--card'>
            <form onSubmit={Analysisformhandler}>


                <div className='Analysis-card-data'>
                    <p className='Analysis-data-parameter'>Analysis Name</p>
                    <input className='Analysis-input-parameter-name' type='text' value={AnalyisisName} onChange={setAnalysisNamehandler} />
                </div>
                <br>
               </br>

               <hr>
               </hr>

                <p style={{fontSize:"15px",textAlign:"justify"}}>Backoff factor decides degree to which the search for nearby sites is restricted. Higher backoff values mean closer range to choose for nearby sites</p>
                <div className='Analysis-card-data'>
                    <p className='Analysis-data-parameter'>Backoff-factor</p>
                    <input className='Analysis-input-parameter' type='text' value={backoff} onChange={backoffhandler} />
                </div>
                <hr>
               </hr>
                <br>
               </br>

               
                <p style={{fontSize:"15px"  ,textAlign:"justify"}}>M: Choose charger/vehicle type </p>
                <div className='Analysis-card-data-M'>
                    <p className='Analysis-data-parameter-m'>M</p>
                    <div className='Analysis-card-M-special'>
                        <div className='label-group'>
                            <input className='Analysis-input-parameter-M' type='checkbox' id="3WS" value="3WS" onChange={MhandleCheckboxChange} />
                            <label className='Analysis-input-parameter-M-label'>3WS</label>
                        </div>

                        <div className='label-group'>
                            <input className='Analysis-input-parameter-M' type='checkbox' id="4WS" value="4WS" onChange={MhandleCheckboxChange} />
                            <label className='Analysis-input-parameter-M-label'>4WS</label>
                        </div>

                        <div className='label-group'>
                            <input className='Analysis-input-parameter-M' type='checkbox' id="4WF" value="4WF" onChange={MhandleCheckboxChange} />
                            <label className='Analysis-input-parameter-M-label'>4WF</label>
                        </div>
                    </div>
                </div>
                <hr>
               </hr>
                 <br>
                 </br>
                <p style={{fontSize:"15px" ,textAlign:"justify"}}>Duration of analysis. typically assumed 3 years</p>

                <div className='Analysis-card-data-year'>
                    <p className='Analysis-data-parameter' style={{marginTop:"10px"}}>Years of Analysis</p>
                    <div className='Analysis-card-year-special'>
                        <div className='label-group'>
                            <input className='Analysis-input-parameter-M' type='checkbox' id="1" value="1" onChange={yearOfAnalysishandler} />
                            <label className='Analysis-input-parameter-M-label'>1</label>
                        </div>
                        <div className='label-group'>
                            <input className='Analysis-input-parameter-M' type='checkbox' id="2" value="2" onChange={yearOfAnalysishandler} />
                            <label className='Analysis-input-parameter-M-label'>2</label>
                        </div>
                        <div className='label-group'>
                            <input className='Analysis-input-parameter-M' type='checkbox' id="3" value="3" onChange={yearOfAnalysishandler} />
                            <label className='Analysis-input-parameter-M-label'> 3</label>
                        </div>
                    </div>
                </div>
                <br>
               </br>

                <hr>
               </hr>

{/* 
                <div className='Analysis-card-data row' style={{ marginTop: "40px", textAlign: 'left' }}>
                    <p className='Analysis-data-parameter col-md-6'>Capex 2W</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={capex2W} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="2500" max="1500000" value={capex2W} onChange={setcapex2Whandler} className="Analysis-slider" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>2500</p>
                        <p className='Analysis-RangeValue_data'>15000000</p>
                    </div>
                </div> */}
                {/* <div className="Analysis-slidecontainer">
                </div> */}
 
                 {/* <p style={{fontSize:"15px",marginBottom:"auto"}}>Capex requirement (Rs) for 3-wheeler slow charging station </p> */}
               
                <div className='Analysis-card-data row' style={{ marginTop: "50px", textAlign: 'left' }} >
                    
                <p style={{fontSize:"15px"}}>Capex requirement (Rs) for 3-wheeler slow charging station </p>
                    <p className='Analysis-data-parameter col-md-6'>Capex 3WS</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={capex3WS} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="2500" max="1500000" value={capex3WS} onChange={setcapex3WShandler} className="Analysis-slider" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>2500</p>
                        <p className='Analysis-RangeValue_data'>15000000</p>
                    </div>
                </div>
               <br>
               </br>
              
               <br>
               </br>

           
            
                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}>Capex requirement (Rs) for 4-wheeler slow charging station</p>
                    <p className='Analysis-data-parameter col-md-6'>Capex 4WS</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={capex4WS} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="2500" max="1500000" value={capex4WS} onChange={setcapex4WShandler} className="Analysis-slider" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>2500</p>
                        <p className='Analysis-RangeValue_data'>15000000</p>
                    </div>
                </div>

                <br>
               </br>

               <br>
               </br>

               
                
                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}> Capex requirement (Rs) for 4-wheeler fast charging station</p>
                    <p className='Analysis-data-parameter col-md-6'>Capex 4WF</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={capex4WF} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="2500" max="1500000" value={capex4WF} onChange={setcapex4WFhandler} className="Analysis-slider" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>2500</p>
                        <p className='Analysis-RangeValue_data'>15000000</p>
                    </div>
                </div>
                <br>
               </br>
               <br>
               </br>
                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                    <p className='Analysis-data-parameter col-md-6'>Hoarding capex cost (Rs)</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={hoardinfcost} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="10000" max="1500000" value={hoardinfcost} onChange={sethoardingcosthandler} className="Analysis-slider" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>10000</p>
                        <p className='Analysis-RangeValue_data'>15000000</p>
                    </div>
                </div>
                <br>
                </br>
                <br>
               </br>

                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                    <p className='Analysis-data-parameter col-md-6'>Kiosk capex cost (Rs)</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={kioskcost} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="10000" max="2500000" value={kioskcost} onChange={setkioskhandler} className="Analysis-slider" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>10000</p>
                        <p className='Analysis-RangeValue_data'>25000000</p>
                    </div>
                </div>

                {/* <br>
                </br> */}
               <br>
               </br>
                
               
                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}> Percentage of vehicles converted to EV during year 1 </p>
                    <p className='Analysis-data-parameter col-md-6'>Year 1 Conversion</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={year1conversion} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.01" max="1" value={year1conversion} onChange={setyear1conversionhandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.01</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>

                <br>
               </br>

                <br>
                </br>

                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}> Percentage of vehicles converted to EV during year 2</p>
                    <p className='Analysis-data-parameter col-md-6'>Year 2 Conversion</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={year2conversion} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.01" max="1" value={year2conversion} onChange={setyear2conversionhandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.01</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>
                <br>
                </br>
                <br>
               </br>

                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}>  Percentage of vehicles converted to EV during year 3</p>
                    <p className='Analysis-data-parameter col-md-6'>Year 3 Conversion</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={year3conversion} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.01" max="1" value={year3conversion} onChange={setyear3conversionhandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.01</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>
                <br>
                </br>
                <br>
               </br>

                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}>Percentage of vehicle traffic on holidays as a fraction of weekday traffic</p>
                    <p className='Analysis-data-parameter col-md-6'>holiday_percentage</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={holidaypercentage} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.1" max="1" value={holidaypercentage} onChange={setholidaypercentagehandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.1</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>


                <br>
               </br>

                <br>
                </br>

               
                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}>Percentage of EVs that opt for fast charging</p>
                    <p className='Analysis-data-parameter col-md-6'>fast charging</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={fastcharging} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.01" max="1" value={fastcharging} onChange={setfastcharginghandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.01</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>

                <br>
                </br>
                <br>
               </br>

               
                

                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}>Percentage of EVs that opt for slow charging</p>
                    <p className='Analysis-data-parameter col-md-6'>slow charging</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={slowcharging} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.01" max="1" value={slowcharging} onChange={setslowcharginghandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.01</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>
                <br>
                </br>
                <br>
               </br>


                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}> Indicates whether clustering should be carried out or not</p>
                    <p className='Analysis-data-parameter col-md-6'>cluster</p>
                    <input className='Analysis-input-parameter col-md-6' type='checkbox' onChange={setclusterCheckhandler} />
                </div>


                <br>
                </br>
                <br>
               </br>

                
                <div className='Analysis-card-data row' style={{ marginTop: "30px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}> Clustering threshold that decides how many sites have low utilization</p>
                    <p className='Analysis-data-parameter col-md-6'>cluster_th</p>
                    <input className='Analysis-input-parameter col-md-6' type='text' value={clusterth} />
                    <input className='col-md-12' style={{ marginLeft: "15px", marginRight: "15px" }} type="range" min="0.01" max="1" value={clusterth} onChange={setclusterthhandler} className="Analysis-slider" step="0.01" />
                    <div className="Analysis-RangeValue col-md-10">
                        <p className='Analysis-RangeValue_data'>0.01</p>
                        <p className='Analysis-RangeValue_data'>1</p>
                    </div>
                </div>
                <br>
                </br>

                <br>
                </br>

                
                <div className='Analysis-card-data row' style={{ marginTop: "70px", textAlign: 'left' }} >
                <hr>
               </hr>
                <p style={{fontSize:"15px"}}>  Indicates whether dendrogram should be displayed. This helps in deciding the clustering threshold. </p>
                    <p className='Analysis-data-parameter col-md-6'>plot dendogram</p>
                    <input className='Analysis-input-parameter' type='checkbox' onChange={setdendogramCheckhandler} />
                </div>

                <br>
                </br>

                <br>
               </br>


                <div className='Analysis-card-data row' style={{ marginTop: "50px", textAlign: 'left' }} >
                    <p className='Analysis-data-parameter col-md-6'>usedefaults</p>
                    <input className='Analysis-input-parameter' type='checkbox' onChange={setusedefaultcheckhandler} />
                </div>

                <button className='Analysis-button'>{checkresponse == "" ? "Run Analysis" : checkresponse}</button>
            </form>
        </div>
    )
}

export default AnalysisHelper