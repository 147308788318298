import React from 'react'
import './index.css'
import authcontext  from '../../Store/auth-context';
function CreateSiteModal(props) {


    const ctx = React.useContext(authcontext)

    function submitformhandler(event) {
        event.preventDefault();

        fetch('https://api.evci.in/rest/v1/site/create', {
            method: 'POST',
            body: JSON.stringify({
                name:createsitename,
                st:ctx,
                city1:createcity1,
                city2:createcity2,
                distance:createdistance,
                type:iscreatprivate?"private":"public"
          
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
            })
            .then(function(response){ 
            return response.json()})
            .then(function(data)
            {  if(data.ack=='0'){
            
                window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
              }
        
                console.log(data)  
          }).catch(error => console.error('Error:', error)); 
        props.setcreatesitehandler(false)

    }

    const [createsitename, setcreatesitename] = React.useState('')
    const [createcity1, setcreatecity1] = React.useState('')
    const [createcity2, setcreatecity2] = React.useState('')
    const [createdistance, setcreatedistance] = React.useState('')
    const [iscreatprivate, setiscreatprivate] = React.useState(false)



    function setcreatesitenamehandler(e) {
        setcreatesitename(e.target.value)
    }

    function setcreatecity1handler(e) {
        setcreatecity1(e.target.value)
    }

    function setcreatecity2handler(e) {
        setcreatecity2(e.target.value)
    }

    function setcreatedistancehandler(e) {
        setcreatedistance(e.target.value)
    }

    const handlecheckbox = (e) => {
        const { value, checked } = e.target;
        setiscreatprivate(checked)
    }

    return (
        <div className="CreateSite-modalBackground">
            <div className="CreateSite-modalContainer row">
                <div className="CreateSite-titleCloseBtn">
                    <button
                        onClick={() => {
                            props.setcreatesitehandler(false)
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h3>Create a New Site</h3>
                </div>
                <div className="Create-sitebody">
                    <form className='create-form' onSubmit={submitformhandler}>
                        <label className='create-modal-label'>Enter Site Name:</label>
                        <input type='text' className='create-site-name' value={createsitename} onChange={setcreatesitenamehandler} />

                        <br>
                        </br>
                        <label className='create-modal-label'>City 1:</label>
                        <input className='create-site-name' type="text" value={createcity1} onChange={setcreatecity1handler} />
                        <br>
                        </br>
               
                        <label className='create-modal-label'>City 2:</label>
                        <br>
                        </br>
                        <input className='create-site-name' type="text" value={createcity2} onChange={setcreatecity2handler} />
                        <br>
                        </br>
                        <label className='create-modal-label'>Length of Corridor (in Kms):</label>
                        <input className='create-site-name' type="text" value={createdistance} onChange={setcreatedistancehandler} />

                        <label className='private'>Private:</label>
                        {/* <p className='private-desc'>A private site is accessibly only by people it is explicitely shared with. You can make your site public if you want any user to
                        discover it and use it to run their analysis.</p> */}
                        <div className="form-check form-switch site-toggle-button">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={iscreatprivate} onChange={(e) => handlecheckbox(e)} />
                            <label className="form-check-label site-toggle-button--text" for="flexSwitchCheckDefault" style={{ color: iscreatprivate ? "#0949ad" : "" }}>{iscreatprivate ? "Your site is private" : "Your site is public"}</label>
                        </div>

                        <button className='save--button'>Save</button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default CreateSiteModal