import React from 'react'
import './index.css'
import Dashsiteinformationcard from '../Dashsiteinformationcard';
import AnalysisModal from '../AnalysisModal';
import { useLocation} from 'react-router-dom';

import authcontext  from '../../Store/auth-context';
import { isDisabled } from '@testing-library/user-event/dist/utils';
function Dashsitepage(props) {

    const location = useLocation();
    const [iSNewAnalysisClicked, setNewAnalysisClicked] = React.useState(false)

    const [analysisdata, setanalysisdata] = React.useState([])
 

    const [isfileuploaded,setfileuploaded] = React.useState(false)

    const ctx = React.useContext(authcontext)

    React.useEffect(() => {
        console.log(props.getallsitedata)
        console.log(props.getallsitedata.sid)
        console.log('mysid')
        fetch('https://api.evci.in/rest/v1/analysis/get-by-sid', {
            method: 'POST',
            body: JSON.stringify({
                st: ctx,
                sid: props.getallsitedata.sid
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if(data.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
            
                console.log(data)
      
                setanalysisdata(data.requests)
                console.log("userName" in data.requests[0])
                if('userName' in data.requests[0]){
 
                    props.setusernamehandler(data.requests[0].userName)
                }
            
            }).catch(error => console.error('Error:', error));

        console.log(analysisdata[0])
    }, [])

    React.useEffect(()=>{
        console.log("check for whether files are uploaded or not")
        fetch(`https://api.evci.in/rest/v1/upload/status?st=${ctx}&sid=${props.getallsitedata.sid}`)
          .then(function(response){ 
          return response.json()})
          .then(function(data)
          {
            if(data.ack=='0'){
            
                window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
              }
              if(data.status.sites && data.status.grid && data.status.traffic){
                setfileuploaded(true)
              }
        
            console.log(data)  
        }).catch(error => console.error('Error:', error));
    },[])

    function converttime(obj) {
        let date = new Date(obj);
        return date.toString()
    }
    // !!obj.response.status?"":obj.response.status 
    const cardelements = analysisdata.map(function (obj) {
        return <Dashsiteinformationcard key={obj.sid} task={obj.name} name={obj.response.status} setName={obj.userName} timehappend={obj.displayDate} datastored ={obj} />
    })
    return (
        <div className='dashboard-site-component'>
            {/* <div className='dashboard-google-map'>
                GoogleMap
            </div> */}
                {iSNewAnalysisClicked && <AnalysisModal setNewAnalysisClicked={setNewAnalysisClicked}  getallsitedata={props.getallsitedata} />}
            <div className='dashboard-site-information'>
                <div className='dashboard-information-heading'>
                    <h5>Analysis</h5>
                    {isfileuploaded?<button className='dashboard-analysis-button'  onClick={() => setNewAnalysisClicked(true) } >New Analysis</button>:<button className='dashboard-analysis-button'  onClick={() => setNewAnalysisClicked(true) } disabled>New Analysis</button>}
                </div>
            
                <div className='dashboard-card-space'>
                    {!isfileuploaded && <p style={{textAlign:"center",color:"red"}}>There are files you need to upload in order to run Analyis in the data management section</p>}
                    {cardelements}
                </div>
            </div>
        </div>
    )
}

export default Dashsitepage