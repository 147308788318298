import React from 'react'
import './index.css'
import mainlogo from './../../assests/main-logo.png';
import { Link ,useNavigate} from "react-router-dom";
import authcontext  from '../../Store/auth-context';

function UserMenu(props){

    const values = [
        { id: 1, text: "Site Settings" ,path:"SiteSettings"},
        { id: 2, text: "User Management",path:"UserManagement" },
        { id: 3, text: "Data Management",path:"DataManagement" },
      ];

      const ctx = React.useContext(authcontext)
      const navigate = useNavigate();

      const [activeId, setActiveId] = React.useState(1);

      const headingelements = values.map(function(val){
        return  <Link to={val.path} style={{ textDecoration: 'none',color:'white', paddingLeft: '20px', fontWeight: '100', fontFamily: 'Cabin'}} className={`UserMenu--parameter ${activeId === val.id ? "active":"" }`}> <p key={val.id} onClick={() => setActiveId(val.id)} >{val.text}</p></Link>
      })

      function deletesitehandler(){
        console.log("clickdelete")
        fetch(`https://api.evci.in/rest/v1/site/delete?st=${ctx}&sid=${props.getallsitedata.sid}`)
        .then(function(response){ 
        return response.json()})
        .then(function(data)
        {  if(data.ack=='0'){
      
          window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
        }
  
          console.log(data)  
      }).catch(error => console.error('Error:', error));
       navigate('/');
      }

    return(
     <div className="UserMenu--container">
        <Link to="" className="UserMenu--logo"> <img  src={mainlogo} alt="main-logo"/></Link>
        {/* <h4 className='UserMenu--text'>UserMenu</h4> */}
        {headingelements}
        <h4 className='UserMenuu-delete-parameter' onClick={deletesitehandler}>Delete Site</h4>
     </div>
    )
}

export default UserMenu


{/* <h5 key={val.id} onClick={() => setActiveId(val.id)} className={`UserMenu--parameter ${activeId === val.id ? "active":"" }`}><Link to={val.path} style={{ textDecoration: 'none',color:'white  '} }>{val.text}</Link></h5> */}