import React from 'react'
import './index.css'
import mainlogo from './../../assests/main-logo.png';
import { Link ,useNavigate,useLocation} from "react-router-dom";
import DashMenuHelper from '../DashMenuhelper';
import authcontext  from '../../Store/auth-context';
function Menu(props) {


  const [mapdata, setdashmapata] = React.useState([])
  const [typedata, settypedata] = React.useState([])

  const ctx = React.useContext(authcontext)

  const location = useLocation();

  console.log("dashemnu",location.pathname)

 
  if(location.pathname==="/dashsitepage/Analysisdisplay"){
    console.log("dsfa",location.state.data.cluster)
  }

  
  React.useEffect(() => {

    console.log(location.pathname)
    fetch('https://api.evci.in/rest/v1/site/get-data', {
      method: 'POST',
      body: JSON.stringify({
        st: ctx,
        sid: props.getallsitedata.sid
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if(data.ack=='0'){
      
          window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
        }
  
        console.log(data.data.types)
        setdashmapata(data.data.mapData)
        settypedata(data.data.types)
        console.log("hekko")
        console.log(data)
      }).catch(error => console.error('Error:', error));

  }, [])

  const dashmenuelement = typedata.map(function (obj) {
    return <DashMenuHelper place={obj.location} value={obj.count} />
  })

  return (
    <div className="Dash-Menu--container">
      <Link to="/" className="Dash-Menu--logo"> <img src={mainlogo} alt="main-logo" /></Link>
     
      

      {/* <DashMenuHelper/> */}
       {
        location.pathname==="/dashsitepage" && 
        <>
         <h4 className='Dash-Menu--text'>Locations</h4>
        { dashmenuelement}
        </>
       }

       {
        location.pathname==="/dashsitepage/Analysisdisplay" &&<>
        <div className='dashmenu-input'>
           <h4 style={{textAlign:"center",color:"white"}}>Input values</h4>
           <hr></hr>
          {/* <p className='input-data-label'><span className='label-heading'>Analysis Name</span>{location.state.name}</p> */}
          <p className='input-data-label'><span className='label-heading'>Backoff-factor:</span>{location.state.data.backoff_factor}</p>
          <p className='input-data-label'><span className='label-heading'>M:</span>{location.state.data.M}</p>
          <p className='input-data-label'><span className='label-heading'>Years of Analysis:</span>{location.state.data.years_of_analysis}</p>
          <p className='input-data-label'><span className='label-heading'>Capex 3WS:</span>{location.state.data.capex_3WS}</p>
          <p className='input-data-label'><span className='label-heading'>Capex 4WS:</span>{location.state.data.capex_4WS}</p>
          <p className='input-data-label'><span className='label-heading'>Capex 4WF:</span>{location.state.data.capex_4WF}</p>
          <p className='input-data-label'><span className='label-heading'>Kiosk Cost:</span>{location.state.data.kiosk_cost}</p>
          <p className='input-data-label'><span className='label-heading'>Year 1 Conversion:</span>{location.state.data.year1_conversion}</p>
          <p className='input-data-label'><span className='label-heading'>Year 2 Conversion:</span>{location.state.data.year2_conversion}</p>
          <p className='input-data-label'><span className='label-heading'>Year 3 Conversion:</span>{location.state.data.year3_conversion}</p>
          <p className='input-data-label'><span className='label-heading'>Fast Charging:</span>{location.state.data.fast_charging}</p>
          <p className='input-data-label'><span className='label-heading'>Slow Charging:</span>{location.state.data.slow_charging}</p>
          <p className='input-data-label'><span className='label-heading'>Cluster:</span>{location.state.data.cluster===true?"true":"false"}</p>
          <p className='input-data-label'><span className='label-heading'>Cluster Threshold:</span>{location.state.data.cluster_th}</p>
          <p className='input-data-label'><span className='label-heading'>Plot Dendogram:</span>{location.state.data.plot_dendrogram===true?"true":"false"}</p>
          <p className='input-data-label'><span className='label-heading'>Use Defaults:</span>{location.state.data.use_defaults===true?"true":"false"}</p>
          
        </div>
        
        </>
       }
      {/* <DashMenuHelper/>
        <DashMenuHelper/>
        <DashMenuHelper/>
        <DashMenuHelper/> */}
        <br/>
      <h5 className="Dash-Menu--parameter">{`Corridor Length: ${props.getallsitedata.distance} kms`}</h5>
    </div>
  )
}

export default Menu