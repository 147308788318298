import React from 'react'
import './index.css'
import openLogo from './../../assests/sidebar-open-logo.png';
import closeLogo from './../../assests/sidebar-close-logo.png';
import { Link } from "react-router-dom";



function Sidebar(props){

 

  const [activeId, setActiveId] = React.useState();



  function clickhandler(id){
    setActiveId(id)
    props.menuClickhandler(id)
  }


  console.log(props.buttonclicked.uIstate)
 
   const listelemnents = props.values.map(function(val){
    return <Link to={val.path} style={{ textDecoration: 'none',color:'white'}}><li key={val.id} onClick={() => clickhandler(val.id)} className={`Sidebar--item ${activeId === val.id ? "active":""}`}><span><img src={val.imgsrc} alt="dashboard--logo"/></span><span>{val.text }</span></li></Link>
   })

  
  return(
    <div className="Sidebar">
      <div className="Sidebar--container">
       <ul className="Sidebar--list">
        {listelemnents}
       </ul>
    </div>
    </div>
   
  )
}

export default Sidebar


{/* <li key={val.id} onClick={() => clickhandler(val.id)} className={`Sidebar--item ${activeId === val.id ? "active":""}`}><span><img src={val.imgsrc} alt="dashboard--logo"/></span><span><Link to={val.path} style={{ textDecoration: 'none',color:'white  ' }}>{val.text }</Link></span></li> */}

// before menu collapse changes
{/* <img className='Sidebar--open-icon' src={props.buttonclicked.uIstate===false?openLogo:closeLogo} alt ="open-button" onClick={props.togglehandler}/> */}

//after menu collapse changes
// return <Link to={val.path} style={{ textDecoration: 'none',color:'white  ' }}><li key={val.id} onClick={() => clickhandler(val.id)} className={`Sidebar--item ${activeId === val.id ? "active":""}`}><span><img src={val.imgsrc} alt="dashboard--logo"/></span><span>{val.text }</span></li></Link>