import React from 'react'

import authcontext  from '../../Store/auth-context';
function Protected(props){

    const ctx = React.useContext(authcontext)
    React.useEffect(()=>{
        console.log('33')
        let token = localStorage.getItem('getauthtoken')
        console.log('token',token)
        console.log('ctx',ctx)
        // if(ctx===null){
        //     window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
        // }
    },[])

    return(

        <div>
           { props.children}
        </div>
    )
}

export default Protected