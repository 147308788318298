import React, { useEffect } from 'react'
import './index.css'
import uploadinglogo from './../../assests/uploding-logo.png';
import downloadinglogo from './../../assests/downloading-logo.png';
import excel from './../../assests/excel.png';
import grid from './../../assests/grid.xlsx';
import sites from './../../assests/sites.xlsx';
import traffic from './../../assests/traffic.xlsx';
import { useDropzone } from "react-dropzone"

function DataManagementHelper(props) {

  const [files, setFiles] = React.useState([])

  useEffect(() => {
    props.apihandler(files)
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })
console.log(props.userinformation)
  console.log(props.title, files)
  const ourfiles = files.map((file) => (
    <div key={file.name}>
      <div>
        <img src={excel} style={{ width: "20px", height: "20px" }} alt="preview" />
      </div>
    </div>
  ))

  function downloadsamplehandler(val) {
    let link = document.createElement("a");
    link.download = val;
    if (val === "grid") {
      link.href = grid
    }

    if (val === "sites") {
      link.href = sites
    }

    if (val === "traffic") {
      link.href = traffic
    }
    link.click();
  }
  return (
    <>
      <div className='data-management-uploading-card'>
        <div className='label-container'>
          <div className='card-labels'>
            <h5 className='label-heading-text'>{props.title}</h5>
            <p className='label-desc'>{props.description}</p>
          </div>
          <div className='card-download' onClick={() => downloadsamplehandler(props.namefile)}>
            <img className='download--logo' src={downloadinglogo} alt="dowload-logo" />
            <p className='download-text'>Download Sample</p>
          </div>
        </div>
        {props.filestatus ? <button className='download-data' onClick={() => props.downloadclickhandler(props.namefile)}>{`Download ${props.namefile}.xlsx file`}</button> : files.length > 0 ? <button className='download-data' onClick={() => props.downloadclickhandler(props.namefile)}>{`Download ${props.namefile} file`}</button> : null}
        {props.userinformation == "admin"  && <>
          <div className='data-uploading' {...getRootProps()}>

            <input {...getInputProps()} />
            {/* {props.filestatus || files.length>0 ?<img src={excel} style={{ width: "20px", height: "20px" }} alt="preview" />:null} */}
            {
              props.filestatus ? <>
                <div>{ourfiles}</div>
              </> :
                files.length == 0 ? <img className='data-uploading-image' src={uploadinglogo} alt="uploading-logo" /> : <>
                      <div>{ourfiles}</div>
                </>
            }

           <p className='data-uploading-description'> {props.filestatus ? 'files uploaded Already!!Click Again to upload file' : files.length == 0 ? 'Drag and drop a file to upload or click to upload files' : 'files uploaded !!Click Again to upload file'}</p>
          </div>
          <p>{props.msg}</p>
        </>}
        {props.userinformation == "owner"  && <>
          <div className='data-uploading' {...getRootProps()}>

            <input {...getInputProps()} />
            {/* {props.filestatus || files.length>0 ?<img src={excel} style={{ width: "20px", height: "20px" }} alt="preview" />:null} */}
            {
              props.filestatus ? <>
                <div>{ourfiles}</div>
              </> :
                files.length == 0 ? <img className='data-uploading-image' src={uploadinglogo} alt="uploading-logo" /> : <>
                      <div>{ourfiles}</div>
                </>
            }

           <p className='data-uploading-description'> {props.filestatus ? 'files uploaded Already!!Click Again to upload file' : files.length == 0 ? 'Drag and drop a file to upload or click to upload files' : 'files uploaded !!Click Again to upload file'}</p>
          </div>
          <p>{props.msg}</p>
        </>}
        {props.userinformation === "user" && <>
          <div className='data-uploading'>
          {
              props.filestatus ? <><img src={excel} style={{ width: "20px", height: "20px" }} alt="preview" />
                <div>{ourfiles}</div>
              </> :
                files.length == 0 ? <img className='data-uploading-image' src={uploadinglogo} alt="uploading-logo" /> : <>
                  <div>{ourfiles}</div>
                </>
            }
            <div/>
           <p className='data-uploading-description'> You are not Admin, you are not allowed to upload files</p>
          </div>

        </>}
      </div>
    </>
  )
}

export default DataManagementHelper


// {files.length==0?<img className='data-uploading-image' src={uploadinglogo} alt="uploading-logo" />:
// <div>{ourfiles}</div>}

{/* <p className='data-uploading-description'>{files.length==0?'Drag and drop a file to upload or click to upload files':'files uploaded'}</p> */ }