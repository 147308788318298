import React from 'react'
import './index.css'
import downloadarrow from './../../assests/download-arrow.png';
import dotsicon from './../../assests/dots-icon.png';
import editicon from './../../assests/edit.png';
import deleteicon from './../../assests/delete.png';
import AddUserComponent from '../AddUserComponent';
import EditUserComponent from '../EditUserComponent';

import authcontext  from '../../Store/auth-context';

const TABLEDUMMYDATA = [
    {
        id: 1,
        FirstName: 'Mahesh',
        LastName: 'Patankar',
        email: "mahesh@mpensystems.com",
        Type: "owner"
    },
    {
        id: 2,
        FirstName: 'Prajakta',
        LastName: 'Adhikari',
        email: "prajkta@mpensystems.com",
        Type: "admin"
    },
    {
        id: 3,
        FirstName: 'Ira',
        LastName: 'Prem',
        email: "ira@mpensystems.com",
        Type: "admin"
    },
    {
        id: 4,
        FirstName: 'Asmita',
        LastName: 'Ekawade',
        email: "asmita@mpensystems.com",
        Type: "user"
    }
]


function UserManagement(props) {
    const [iSAddUserclicked, setAddUserClicked] = React.useState(false)
    const [iSeditUserclicked, seteditUserClicked] = React.useState(false)

    const [userlist, setUserlist] = React.useState(TABLEDUMMYDATA)

    const [isChecked, setisChecked] = React.useState([]);

    const [userdata,setUserdata] = React.useState([])

    const [editdata,seteditdata] = React.useState([])
    const ctx = React.useContext(authcontext)

    React.useEffect(()=>{
        console.log("userdata---------------------")
        console.log("usermanagement")
        fetch('https://api.evci.in/rest/v1/site/get-users', {
            method: 'POST',
            body: JSON.stringify({
                st:ctx,
                sid:props.getallsitedata.sid
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
            })
            .then(function(response){ 
            return response.json()})
            .then(function(data)
            {//console.log(data.users)  
                if(data.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
            
                setUserdata(data.users)
                console.log(userdata)
          }).catch(error => console.error('Error:', error)); 

          console.log(userdata)
    },[])

    const [usersiterole,setusersiterole] = React.useState('admin');
    React.useEffect(()=>{
        console.log("userdata---------------------")
        console.log("user-access")
        fetch('https://api.evci.in/rest/v1/site/user-access', {
            method: 'POST',
            body: JSON.stringify({
                st:ctx,
                sid:props.getallsitedata.sid
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
            })
            .then(function(response){ 
            return response.json()})
            .then(function(data)
            {//console.log(data.users)  
                if(data.ack=='0'){
                
                    // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                    // window.location.replace("abc")
                    // alert(data.ack)
                  }
            
            // setusersiterole(data.role)
             console.log(data.role)

          }).catch(error => console.error('Error:', error)); 

        
    },[])

    function upadatlisthandler(listdata) {
        setUserlist(prev => {
            return [listdata, ...prev]
        })
    }

    const handlecheckbox = (e) => {
        const { value, checked } = e.target;
        console.log(value);
        if (checked) {
            setisChecked([...isChecked, value]);
        } else {
            setisChecked(isChecked.filter((e) => e !== value));
        }
    }

    const alldeletehandler = async () => {
        console.log(isChecked);
        if (isChecked.length !== 0) {

            setUserlist(function (prevdata) {

                return prevdata.filter((prevarr) => !isChecked.includes(prevarr.id.toString()))

            })
        }
       
    }

    function deletehandler(obj){
        //check for objid
        fetch(`https://api.evci.in/rest/v1/site/delete-user?st=${ctx}&_id=${obj._id}`)
        .then(function(response){ 
        return response.json()})
        .then(function(data)
        {
            if(data.ack=='0'){
            
                window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
              }
        
          console.log(data)  
      }).catch(error => console.error('Error:', error));
      window.location.reload(false);
    }

    function edithandler(val){
        seteditUserClicked(true)
        seteditdata(val)
    }

    return (
        <>
            <div className='userManegemnet-container'>
                <div className='UserManagement-button'>
               {usersiterole==="admin" && <button className='Add-new-user-button' onClick={() => setAddUserClicked(true)}> Add New User</button>}
               {usersiterole==="owner" && <button className='Add-new-user-button' onClick={() => setAddUserClicked(true)}> Add New User</button>}
               {usersiterole==="user" && <button className='Add-new-user-button' onClick={() => setAddUserClicked(true)} disabled> Add New User</button>}
                </div>
                {iSAddUserclicked && <AddUserComponent setAddUserClicked={setAddUserClicked} upadatlist={upadatlisthandler} getalldata={props.getallsitedata} />}
                {iSeditUserclicked && <EditUserComponent seteditUserClicked={seteditUserClicked} getalldata={props.getallsitedata} editdata={editdata} />}
                <div className='Userlist-Component'>
                    <div className='Userlist-label'>
                        <h1 className='list-name'>User Management</h1>
                        {/* <div className='list-button-group'>
                            <button className='list-delete-button' onClick={alldeletehandler}>Delete  <img className="button-download-image" src={downloadarrow} alt="downloadicon" /></button>
                            <button className='extra-button'><img className="button-download-image" src={dotsicon} alt="downloadicon" /></button>
                        </div> */}
                    </div>
                    <div className='User-table-component'>
                        <table className="table mytable">
                            <thead>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">First name</th>
                                    {/* <th scope="col">Last name</th> */}
                                    <th scope="col">Email address</th>
                                    <th scope="col">Role</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userdata.map(function (val,index) {
                                        return <tr key={val._id}>
                                            {/* <th scope="row" className='checkbox-input'><input type='checkbox' value={val.id} checked={val.isChecked} onChange={(e) => handlecheckbox(e)} /></th> */}
                                            <td>{val.user.FirstName}</td>
                                            {/* <td>{val.user.LastName}</td> */}
                                            <td>{val.user.email}</td>
                                            <td>{val.user.Type}</td>
                                            {usersiterole==="admin" || usersiterole==="owner"?<td><img onClick={()=>edithandler(val)} style={{cursor:"pointer"}}src={editicon} alt ="editicon"/></td>:<td><img onClick={()=>alert('You are not Admin')} style={{cursor:"pointer"}}src={editicon} alt ="editicon"/></td>}
                                            {usersiterole==="admin" || usersiterole==="owner"?<td><img onClick={()=>deletehandler(val)}style={{cursor:"pointer"}}src={deleteicon} alt ="editicon"/></td>:<td><img onClick={()=>alert('You are not Admin')}style={{cursor:"pointer"}}src={deleteicon} alt ="editicon"/></td>}
                        
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='User-Description'>
                    <h4 className='desc-heading'>Owner:</h4>
                    <p className='desc-paragraph'>Is the only user with permissions to delete a site and assign new owners. A site must have exactly 1 owner.</p>
                    <h4 className='desc-heading'>Admin:</h4>
                    <p className='desc-paragraph'>A site may have multiple administrators. Admins have full permissions except for permission to delete the site. Only admins and
                        owner have permission to edit site settings, upload / manage site data, and manage user permissions. Admins may assign admin
                        roles to other users, but cannot change the owner. </p>
                    <h4 className='desc-heading'>User:</h4>
                    <p className='desc-paragraph'>Can use the site to run, save and share their analysis. Users can view other users and their roles.</p>
                </div>
            </div>
        </>
    )
}

export default UserManagement