import React from 'react'
import './index.css'
import downloadicon from './../../assests/White-download-arrow.png';

function IndividualAnalysis(){
    return(
        <>       
        <div className='Individual-button-group'>
        <button className='Individual-Analysis-button-1'><img className="button-download-image" src={downloadicon} alt="downloadicon"/> Download Initial Output</button>
        <button className='Individual-Analysis-button-2'><img className="button-download-image" src={downloadicon} alt="downloadicon"/> Download Final Output</button>
        </div>
         <div className='Individual-Analysis-component'>
            <div className='Individual-Analysis-google-map'>
                GoogleMap
            </div>
            <div className='Individual-Analysis-information'>
               <div className='Analysis-parameters'>
               <h5 className='Analysis-name'>Clustering Analysis</h5>
               <p className='Analysis-desc'>Run a clustering analysis to see the effect of clustering on the selected data</p>
               </div>
               <button className='Individual-Analysis-button'>Find Clusters</button>
          </div>
         </div> 
         </>

    )
}

export default IndividualAnalysis