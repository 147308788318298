import React from 'react'
import './index.css'

import DataManagementHelper from '../DataManagementHelper';
import authcontext  from '../../Store/auth-context';

// const getgrid = (sid) => {
//     let list = localStorage.getItem(`grid_${sid}`);
//     // let list = localStorage.getItem('gridmsg');
//     console.log(list);
  
//     if (list) {
//         return JSON.parse(localStorage.getItem(`grid_${sid}`));
//     } else {
//         return ''
//     }
//   }

//   const getsites = (sid) => {
//     // let list = localStorage.getItem('sitesmsg');
//     let list = localStorage.getItem(`sites_${sid}`)
//     console.log(list);
  
//     if (list) {
//         return JSON.parse(localStorage.getItem(`sites_${sid}`));
//     } else {
//         return ''
//     }
//   }

//   const gettraffic = (sid) => {
//     let list = localStorage.getItem(`traffic_${sid}`);
//     //let list = localStorage.getItem('trafficmsg');
    

//     console.log(list);
  
//     if (list) {
//         return JSON.parse(localStorage.getItem(`traffic_${sid}`));
//     } else {
//         return ''
//     }
//   }

function DataManagement(props) {

    // const [allfiles, setallFiles] = React.useState({
    //     gridfiles:"",
    //     nearbyfile:"",
    //     trafficdatafile:""
    // })
    // https://api.evci.in/rest/v1/upload/status?st=test&&sid=MK233HU2

    const ctx = React.useContext(authcontext)

    const [filestatus,setfilestatus]= React.useState({
        "ack": 1,
        "status": {
          "sites": false,
          "grid": false,
          "traffic": false
        }
      })

      
    //   const [gridmsg,setgridmsg] = React.useState(props.getallsitedata.sid===getgrid().sid? getgrid().msg:"")
    //   const [sitesmsg,setsitesmsg] = React.useState(props.getallsitedata.sid===getsites().sid? getsites().msg:"")
    //   const [trafficmsg,settrafficmsg] = React.useState(props.getallsitedata.sid===gettraffic().sid? gettraffic().msg:"")


    //   const [gridmsg,setgridmsg] = React.useState(getgrid(props.getallsitedata.sid))
    //   const [sitesmsg,setsitesmsg] = React.useState(getsites(props.getallsitedata.sid))
    //   const [trafficmsg,settrafficmsg] = React.useState(gettraffic(props.getallsitedata.sid))

    

    const [gridmsg,setgridmsg] = React.useState("")
    const [sitesmsg,setsitesmsg] = React.useState("")
    const [trafficmsg,settrafficmsg] = React.useState("")



         function downloadclickhandler(val){
            fetch(`https://api.evci.in/rest/v1/download/file?st=${ctx}&sid=${props.getallsitedata.sid}&fileName=${val}`)
            .then(function(response){ 
            return response.blob()})
            .then(function(blob)
            {
                if(blob.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
            
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = val+'.xlsx';
                alink.click();
          }).catch(error => console.error('Error:', error));
         }
         const [usersiterole,setusersiterole] = React.useState('admin');
         React.useEffect(()=>{
            console.log("userdata---------------------")
            console.log("user-access")
            fetch('https://api.evci.in/rest/v1/site/user-access', {
                method: 'POST',
                body: JSON.stringify({
                    st:ctx,
                    sid:props.getallsitedata.sid
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
                })
                .then(function(response){ 
                return response.json()})
                .then(function(data)
                {//console.log(data.users)  
                    if(data.ack=='0'){
                    
                        // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                      }
                    //   setusersiterole(data.role)
                 console.log(data)
   
              }).catch(error => console.error('Error:', error)); 
    
            
        },[])

    React.useEffect(()=>{
        console.log("helllo ddatat")
        fetch(`https://api.evci.in/rest/v1/upload/status?st=${ctx}&sid=${props.getallsitedata.sid}`)
          .then(function(response){ 
          return response.json()})
          .then(function(data)
          {
            if(data.ack=='0'){
            
                window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
              }
        
            console.log(data)  
            setfilestatus(data)
        }).catch(error => console.error('Error:', error));
    },[])

    function gridapihandler(gridfile) {
        if (gridfile.length === 0)
            return;

        console.log("firstentry", gridfile)
        var formData = new FormData();
        formData.append("file", gridfile[0], 'grid.xlsx');

        console.log(props.getallsitedata.sid)
        console.log(formData)
        // console.log(`/rest/v1/grid/upload-file?sid=${props.getallsitedata.sid}`)
        fetch(`https://api.evci.in/rest/v1/grid/upload-file?sid=${props.getallsitedata.sid}`, {
            method: 'POST',
            body: formData,
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if(data.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
               if(data.missing.missing ===true){
                const msg = `You are missing ${data.missing.columns} from the file ,Please Upload the file again`
                // localStorage.setItem(`grid_${props.getallsitedata.sid}`,JSON.stringify({sid:props.getallsitedata.sid,msg:msg}))
                setgridmsg(msg)
                alert(msg)
            
               }
            //    else{
            //     let msg = ""
            //     localStorage.setItem(`grid_${props.getallsitedata.sid}`,JSON.stringify({sid:props.getallsitedata.sid,msg:msg}))
            //     setgridmsg(msg)
            //     window.location.reload(false);
            //    }
             
                console.log(data)
            }).catch(error => console.error('Error:', error));
    }

    function nearbyapihandler(nearbyfile) {
        if (nearbyfile.length === 0)
            return;
        var formData = new FormData();
        formData.append("file", nearbyfile[0], 'sites.xlsx');
        fetch(`https://api.evci.in/rest/v1/site/upload-file?sid=${props.getallsitedata.sid}`, {
            method: 'POST',
            body: formData
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if(data.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
                  if(data.missing.missing ===true){
                    const msg = `You are missing ${data.missing.columns} from the file ,Please Upload the file again`
                    // localStorage.setItem(`sites_${props.getallsitedata.sid}`,JSON.stringify({sid:props.getallsitedata.sid,msg:msg}))
                    setsitesmsg(msg)
                    alert(msg)
         
                   }
                //    else{
                //     let msg = ""
                //     localStorage.setItem(`sites_${props.getallsitedata.sid}`,JSON.stringify({sid:props.getallsitedata.sid,msg:msg}))
                //     setsitesmsg(msg)
                //     window.location.reload(false);
                //    }
            
                console.log(data)
            }).catch(error => console.error('Error:', error));
    }

    function trafficapihandler(trafficfile) {
        if (trafficfile.length === 0)
            return;

        var formData = new FormData();
        formData.append("file", trafficfile[0], 'traffic.xlsx');
        fetch(`https://api.evci.in/rest/v1/traffic/upload-file?sid=${props.getallsitedata.sid}`, {
            method: 'POST',
            body: formData
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if(data.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
                  if(data.missing.missing ===true){
                    const msg = `You are missing ${data.missing.columns} from the file ,Please Upload the file again`
                    // localStorage.setItem(`traffic_${props.getallsitedata.sid}`,JSON.stringify({sid:props.getallsitedata.sid,msg:msg}))
                    settrafficmsg(msg)
                    alert(msg)
        
                   }
                //    else{
                //     let msg = ""
                //     localStorage.setItem(`traffic_${props.getallsitedata.sid}`,JSON.stringify({sid:props.getallsitedata.sid,msg:msg}))
                //     settrafficmsg(msg)
                //     window.location.reload(false);
                //    }
                 
                console.log(data)
            }).catch(error => console.error('Error:', error));
    }

    return (
        <div className='data-management-container'>
            <div className='data-management-extraspace'>
            </div>
            <div className='datamangement-workpace'>
                <h1 className='data-main-heading'>Data Management</h1><br/>
                <DataManagementHelper userinformation={usersiterole} errormsg={gridmsg} apihandler={gridapihandler} downloadclickhandler={downloadclickhandler} filestatus={filestatus.status.grid} namefile={'grid'} title="Upload grid.xlsx" description="Excel sheet containing coordinates of locations of substations or transformers." />
                <DataManagementHelper userinformation={usersiterole} errormsg={sitesmsg} apihandler={nearbyapihandler} downloadclickhandler ={downloadclickhandler} filestatus={filestatus.status.sites} namefile={'sites'} title="Upload sites.xlsx"
                 description="Excel sheet containing coordinates of places of interest. These can be hotels, fueling stations, existing EV charging points, warehouses, etc"/>
                <DataManagementHelper userinformation={usersiterole} errormsg={trafficmsg} apihandler={trafficapihandler} downloadclickhandler={downloadclickhandler} filestatus={filestatus.status.traffic} namefile={'traffic'}title="Upload traffic.xlsx" description="Excel sheet containing traffic analysis around the highway. It should indicate the total number of vehicles per hour including the LCVs and MCVs"/>
            </div>
        </div>
    )
}

export default DataManagement



