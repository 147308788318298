import React from 'react'
import mainsite from './../../assests/main.png';
import './index.css'

function Dashboardsite(){
    return(
     <div className='Dashboard--site'>
      <h2 className='Dashboard--title'>EVCI Site Planner</h2>
      <img className="Dashboard--image" src={mainsite} alt ="site"/>
     </div>
    )
}

export default Dashboardsite