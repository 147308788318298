import React from 'react'
import './index.css'

import authcontext  from '../../Store/auth-context';

function AddUserComponent(props) {

    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [currentRadioValue, setCurrentRadioValue] = React.useState()

   const ctx = React.useContext(authcontext)
    function firstNamehandler(event) {
        setFirstName(event.target.value)
    }

    function LastNamehandler(event) {
        setLastName(event.target.value)
    }
    function emailhandler(event) {
        setEmail(event.target.value)
    }
    const handleRadioChange = (e) => {
        setCurrentRadioValue(e.target.value);
      };

    function adduserformhandler(event) {
        event.preventDefault();
        console.log("formsubmitted")

        const userdata = {
            FirstName:firstName,
            LastName:lastName,
            email:email,
            Type:currentRadioValue
        }

        props.upadatlist(userdata)
    
        
        fetch('https://api.evci.in/rest/v1/site/add-user', {
            method: 'POST',
            body: JSON.stringify({
             sid:props.getalldata.sid,
             st:ctx,
             role:"Admin",
             user:{
                FirstName:firstName,
                LastName:lastName,
                email:email,
                Type:currentRadioValue
             }
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
            })
            .then(function(response){ 
            return response.json()})
            .then(function(data)
            {
                if(data.ack=='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
            
                console.log(data)  
          }).catch(error => console.error('Error:', error)); 
        props.setAddUserClicked(false)
        // window.location.reload(false);
    }

    return (
        <div className="addmodalBackground">
            <div className="addmodalContainer">
                <div className="addtitleCloseBtn">
                    <button
                        onClick={() => {
                            props.setAddUserClicked(false)
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h3>Invite user to site</h3>
                </div>
                <div className="addbody">
                    <form className='adduserform' onSubmit={adduserformhandler}>
                        <label className='adduserform--label'>First name:</label>
                        <input className="adduserform-input-text" type='text' value={firstName} onChange={firstNamehandler} />
                        <label className='adduserform--label'>Last name:</label>
                        <input className="adduserform-input-text" type='text' value = {lastName} onChange={LastNamehandler} />
                        <label className='adduserform--label'>Email address:</label>
                        <input className="adduserform-input-text" type='email' value={email} onChange={emailhandler} />
                        <p className='adduserform--label'>Select an appropriate user role:</p>
                        <div className='adduser-permissiontype'>
                            <input className="adduserform-input-radio" type="radio" id="type1" name="type" value="Admin" onChange ={handleRadioChange} checked={currentRadioValue === 'Admin'} />
                            <label className='adduserform--label-radio' for="type1">Admin</label>
                        </div>
                        {/* <div className='adduser-permissiontype'>
                            <input className="adduserform-input-radio" type="radio" id="type2" name="type" value="owner" onChange ={handleRadioChange}checked={currentRadioValue === 'owner'}/>
                            <label className='adduserform--label-radio' for="type2">Owner</label>
                        </div> */}
                        <div className='adduser-permissiontype'>
                            <input className="adduserform-input-radio" type="radio" id="type3" name="type" value="user" onChange ={handleRadioChange} checked={currentRadioValue === 'user'}/>
                            <label className='adduserform--label-radio' for="type3">User</label>
                        </div>
                        <input className="addusersubmit" type="submit" value="Invite User" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUserComponent