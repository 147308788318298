import React from 'react'
import './index.css'
import AnalysisHelper from '../AnalysisHelper';

function AnalysisModal(props) { 

    function adduserformhandler(event) {
        event.preventDefault();
 
    }

    return (
        <div className="Analysis-modalBackground">
            <div className="Analysis-modalContainer row">
                <div className="Analysis-titleCloseBtn">
                    <button
                        onClick={() => {
                            props.setNewAnalysisClicked(false)
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h3>Run a New Analysis</h3>
                </div>
                <div className="Analyisis-body">
                   <AnalysisHelper  setNewAnalysisClicked = {props.setNewAnalysisClicked} getallsitedata={props.getallsitedata}/>
                </div>
            </div>
        </div>
    )
}

export default AnalysisModal