import React from 'react'
import Settinglogo from './../../assests/setting-logo.png';
import belllogo from './../../assests/Bell.png';
import avatarlogo from './../../assests/user-logo.png';
import  './Navbar.css'
import { Link ,useNavigate} from "react-router-dom";
import Gravatar from 'react-gravatar'
import authcontext  from '../../Store/auth-context';
import { useLocation } from "react-router-dom";

function Navbar(props){


   const [islogin,setIslogin] = React.useState(props.islogin);
  //  const [userinformation,setuserinformation] = React.useState({
  //   email:"",
  //   role:""
  //  })

   const location = useLocation();
   const navigate = useNavigate();
   const ctx = React.useContext(authcontext)

  //  React.useEffect(()=>{
  //   fetch(`https://api.evci.in/rest/v1/user/get-email?st=${ctx}`)
  //   .then(response => {
  //     return response.json()
  //   })
  //   .then(data => {
  //       if(data.ack===0){
  //         alert('not working')
  //       }
  //       setuserinformation({
  //         email:data.email,
  //         role:data.role
  //       })
  //   });
  //  },[])

   function logouthandler(){
    fetch(`https://api.evci.in/rest/v1/user/logout?st=`)
    .then(response => {
      return response.json()
    })
    .then(data => {
 
      console.log(data)
    });
   
    localStorage.removeItem("getauthtoken")
    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
   }
   
   function adminclickhandler(){
    navigate('/AdminPanel');
   }

    return(
      <div className="navbar--container">
       {!islogin &&  <Link className='navbar--heading' to=""><h1>EVCI@India</h1> </Link>}
       {islogin
        && 
        <>
        <div className='navbar-user-details'>
           { location.pathname !== "/AdminPanel" ? <h4 className='navbar-username'>{props.getallsitedata.name}</h4>:null}
           {/* <p className='navbar-user-route'>{`. ${props.getallsitedata.city1} <=> ${props.getallsitedata.city2}` }</p> */}
        </div>
        <div className='navbar-site-details'>
          {/* <h4 className='navbar-site-name'>{props.getallsitedata.uid}</h4> */}
         <Link to="/" style={{textDecoration:"none"}} className='navbar-sitename-change'>Back to Sites</Link> 
        </div>
        </>}
        <div className='navbar--features'>
          {/* <img className='navbar-setting-logo' src={Settinglogo} alt="setting-icons"/>
          <img src={belllogo} alt="bell-icon"/> */}
          {/* <img src={avatarlogo} alt="avatar-icons"/> */}
          {props.userinformation.role=="admin" && <p onClick={adminclickhandler}style={{marginTop:"16px",cursor:"pointer",fontWeight:"600"}}>Admin</p>}
          <Gravatar email={props.userinformation.email} style={{borderRadius:"100px"}}/>
          <p onClick={logouthandler} style={{marginTop:"16px",cursor:"pointer",fontWeight:"600"}}>Log out</p>
        </div>
      </div>
      )
    }
export default Navbar