import React from 'react'
import './index.css'
import deleteicon from './../../assests/delete.png';
import AdminaddModal from '../AdminaddModal';

import authcontext  from '../../Store/auth-context';


function UserManagement(props) {
    const [iSAddUserclicked, setAddUserClicked] = React.useState(false)
    
    const [userdata,setUserdata] = React.useState({userlist:[]})

    const [editdata,seteditdata] = React.useState([])
    const ctx = React.useContext(authcontext)

    React.useEffect(()=>{
        console.log("userdata---------------------")
        console.log("admin")
        fetch('https://api.evci.in/rest/v1/users?st=test')
            .then(function(response){ 
            return response.json()})
            .then(function(data)
            {//console.log(data.users)  
                if(data.ack==='0'){
                
                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                  }
            
                setUserdata({
                    userlist:data.result.p
                })
                console.log(data.result.row)
          }).catch(error => console.error('Error:', error)); 

          console.log(userdata)
    },[])


//need delete api
    function deletehandler(obj){
        fetch(`https://api.evci.in/rest/v1/user/delete-user`, {
            method: 'POST',
            body: JSON.stringify({
                st:ctx,
                uid:obj.uid
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
            })
        .then(function(response){ 
        return response.json()})
        .then(function(data)
        {
            if(data.ack==='0'){
            
                window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
              }
        
          console.log(data)  
      }).catch(error => console.error('Error:', error));
    //   window.location.reload(false);
    }



    return (
        <>
            <div className='userManegemnet-container'>
                <div className='UserManagement-button'>
                    <button className='Add-new-user-button' onClick={() => setAddUserClicked(true)}>Invite User </button>
                </div>
                {iSAddUserclicked && <AdminaddModal setAddUserClicked={setAddUserClicked} />}
                <div className='Userlist-Component'>
                    <div className='Userlist-label'>
                        <h1 className='list-name'>Total Number of Users:-{userdata.userlist.length}</h1>
                    </div>
                    <div className='User-table-component'>
                        <table className="table mytable">
                            <thead>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Name</th>
                                    <th scope="col">Email address</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userdata.userlist.map(function (val,index) {
                                        return <tr key={val._id}>
                                            {/* <th scope="row" className='checkbox-input'><input type='checkbox' value={val.id} checked={val.isChecked} onChange={(e) => handlecheckbox(e)} /></th> */}
                                            <td>{val.name}</td>
                                            <td>{val.email}</td>
                                            <td><img onClick={()=>deletehandler(val)}style={{cursor:"pointer"}}src={deleteicon} alt ="editicon"/></td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserManagement