import React from 'react'
import DashMenu from '../Components/DashMenu';
import Navbar from '../Components/Navbar/Navbar';
import Sidebar from '../Components/Sidebar';
import AnalysisMenu from '../Components/AnalysisMenu'
import UserMenu from '../Components/UserMenu'
import Dashlogo from './.././assests/dash-logo.png';
import Analysislogo from './.././assests/Analysis-logo.png';
import settinglogo from './.././assests//sidebar--setting-logo.png';
import { useLocation } from "react-router-dom";


const gettoggleid = () => {
    let list = localStorage.getItem('toggle');
    console.log(list);
  
    if (list) {
        return JSON.parse(localStorage.getItem('toggle'));
    } else {
        return {
            id: 1,
            uIstate: false
        }
    }
  }

function Homepage(props) {


    const location = useLocation();

    console.log("Homepage", props)
    const values = [
        { id: 1, text: "Dash", imgsrc: Dashlogo, path: "dashsitepage" },
        { id: 2, text: "Settings", imgsrc: settinglogo, path: "SiteSettings" },
    ];

    const [toggle, setToggle] = React.useState(gettoggleid())
    
 
    
      React.useEffect(()=>{
        localStorage.setItem('toggle',JSON.stringify(toggle))
        },[toggle])

    function togglehandler() {
        console.log("1")
        setToggle(prev => {
            return {
                ...prev,
                uIstate: !prev.uIstate,
            }
        })
    }

    function menuClickhandler(newid) {

        if (toggle.uIstate == true) {
            setToggle(prev => {
                return {
                    ...prev,
                    id: newid,
                    uIstate: false
                }
            })
        } else {
            setToggle(prev => {
                return {
                    ...prev,
                    id: newid,
                }
            })
        }


    }
    // setId = {setCheckId}
    return (
        <>
            {
                location.pathname !== "/" ? <>
                    <Sidebar values={values} buttonclicked={toggle} togglehandler={togglehandler} menuClickhandler={menuClickhandler} />
                    {!toggle.uIstate && toggle.id === 1 && <DashMenu getallsitedata={props.getallsitedata} />}
                    {!toggle.uIstate && toggle.id === 2 && <UserMenu  getallsitedata={props.getallsitedata}/>}
                    <Navbar islogin={true} getallsitedata={props.getallsitedata} userinformation={props.userinformation}/>
                </> :  <Navbar islogin={false} getallsitedata={props.getallsitedata} userinformation={props.userinformation}/>


            }

        </>
    )
}

export default Homepage


// const values = [
//     { id: 1, text: "Dash", imgsrc: Dashlogo, path: "dashsitepage" },
//     { id: 2, text: "Analysis", imgsrc: Analysislogo, path: "IndividualAnalysis" },
//     { id: 3, text: "Setting", imgsrc: settinglogo, path: "SiteSettings" },
// ];


// {!toggle.uIstate && toggle.id === 2 && <AnalysisMenu getallsitedata={props.getallsitedata} />}