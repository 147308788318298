import Siteplanner from './Components/Dashboardsite';
import Dashboardform from './Components/Dashboardform';
import Dashsitepage from './Components/Dashsitepage';
import IndividualAnalysis from './Components/IndividualAnalysis';
import UserManagement from './Components/UserManagement';
import DataManagement from './Components/DataManagement';
import SiteSettings from './Components/SiteSettings';
import Analysisdisplay from './Components/Analysisdisplay';
import Protected from './Components/Protected';
import AdminPanel from './Components/AdminPanel';
import Homepage from "./Pages/Homepage";
import './App.css'
import { Routes, Route, Link,useLocation } from "react-router-dom";
import React  from 'react';
import authcontext  from './Store/auth-context';

import  { Navigate } from 'react-router-dom'
import AdminaddModal from './Components/AdminaddModal';


const getLocalItmes = () => {
  let list = localStorage.getItem('getalldata');
  console.log(list);

  if (list) {
      return JSON.parse(localStorage.getItem('getalldata'));
  } else {
      return {"city1":"Morena","city2":"Gwalior","distance":"100","name":"mysite","_id":"df57d468-0c9b-4a69-8ad8-036442d8746e","type":"public","sid":"F3E5SBP4"};
  }
}

const getauthtoken = () => {
  let list = localStorage.getItem('getauthtoken');
  console.log(list);

  if (list) {
    console.log("session token",list)
      return JSON.parse(localStorage.getItem('getauthtoken'));
  } else {
      return ''
  }
}

function App() {

  // {"city1":"Morena","city2":"Gwalior","distance":"100","name":"mysite","_id":"df57d468-0c9b-4a69-8ad8-036442d8746e","type":"public","sid":"F3E5SBP4"}
  const[getalldata,setalldata] = React.useState(getLocalItmes())

  const location = useLocation();

  const [authtoken,setauthtoken] = React.useState(getauthtoken())
   

  // React.useEffect(()=>{
  //   console.log("here")
  //    console.log(location.search.split('=')[1])//undefined if not in link
  //    if(location.search.split('=')[1]==undefined){
  //     let list = localStorage.getItem('getauthtoken');
  //      if(list==null){
  //       console.log('app.js')
  //       window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
  //      }
  //    }else{
  //     console.log('2')
  //     localStorage.setItem('getauthtoken',JSON.stringify(location.search.split('=')[1]))
  //     setauthtoken(location.search.split('=')[1])
  //    }
    
  // },[authtoken])
  const [userinformation,setuserinformation] = React.useState({
    email:"",
    role:""
   })

   const [siteusername,setsiteusername] = React.useState("")

  React.useEffect(()=>{
    let token = localStorage.getItem('getauthtoken');
    if(token ==null){
      if(location.search.split('=')[1]==undefined){
        // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
        window.location.replace("abc")
      }else{
        console.log('sadffffffffffffffffffffffhg')
        localStorage.setItem('getauthtoken',JSON.stringify(location.search.split('=')[1]))
        setauthtoken(location.search.split('=')[1])
      }
    }
  },[])

  // authtoken
  React.useEffect(()=>{
    fetch(`https://api.evci.in/rest/v1/user/get-email?st=${authtoken}`)
    .then(response => {
      return response.json()
    })
    .then(data => {
        if(data.ack===0){
          alert('not working')
        }
        setuserinformation({
          email:data.email,
          role:data.role
        })
    });
   },[])

   const [inputdata,setinputdata] = React.useState([])

   React.useEffect(()=>{
   localStorage.setItem('getalldata',JSON.stringify(getalldata))
   },[getalldata])

  function setalldatahandler(obj){
    // console.log("app",getalldata)
    // setalldata(prev=>{
    //   return {...prev,obj}
    // })

    setalldata(obj)
    // console.log(getalldata)
  }

  function setinputhandler(obj){
    setinputdata(obj)
  }

  function setusernamehandler(newname){
    setsiteusername(newname)
  }


  return (
    <div className="App">
      <authcontext.Provider value={authtoken}>
       
      <Homepage getallsitedata={getalldata} userinformation={userinformation} />
       <Routes>
        <Route path="/" element={ <Protected><Dashboardform  setalldatahandler={setalldatahandler} getallsitedata = {getalldata}/></Protected> } />
        <Route path="dashsitepage" element={ <Protected ><Dashsitepage setusernamehandler={setusernamehandler} getallsitedata = {getalldata}/></Protected>} />
        {/* <Route path="IndividualAnalysis" element={<IndividualAnalysis />} /> */}
        <Route path="dashsitepage/Analysisdisplay" element={ <Protected><Analysisdisplay setinputhandler={setinputhandler}/> </Protected>}/>
        <Route path="UserManagement" element={ <Protected><UserManagement userinformation={userinformation} getallsitedata={getalldata}/></Protected> } />
        <Route path="DataManagement" element={ <Protected><DataManagement userinformation={userinformation} getallsitedata={getalldata}/></Protected> } />
        <Route path="SiteSettings" element={ <Protected><SiteSettings siteusername={siteusername} getallsitedata={getalldata}/></Protected>} />
        <Route path="AdminPanel" element={ <Protected><AdminPanel/></Protected>} />
        {/* <Route path="Siteplanner" element={<Siteplanner />} /> */}
      </Routes>
      </authcontext.Provider>
    </div>
  );
}

export default App;


{/* <Route path="/" element={<Dashboardform  setalldatahandler={setalldatahandler} getallsitedata = {getalldata}/> } />
<Route path="dashsitepage" element={<Dashsitepage getallsitedata = {getalldata}/>} />
<Route path="IndividualAnalysis" element={<IndividualAnalysis />} />
<Route path="dashsitepage/Analysisdisplay" element={<Analysisdisplay />} setinputhandler={setinputhandler}/>
<Route path="UserManagement" element={<UserManagement getallsitedata={getalldata}/> } />
<Route path="DataManagement" element={<DataManagement getallsitedata={getalldata}/> } />
<Route path="SiteSettings" element={<SiteSettings getallsitedata={getalldata}/>} />
<Route path="Siteplanner" element={<Siteplanner />} /> */}