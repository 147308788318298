import React from 'react'
import './index.css'
import authcontext  from '../../Store/auth-context';


function AdminaddModal(props) {


    const ctx = React.useContext(authcontext)

 

    const [name, setname] = React.useState('')
    const [email, setemail] = React.useState('')
   


    function setnamehandler(e) {
        setname(e.target.value)
    }

    function setemailhandler(e) {
        setemail(e.target.value)
    }

   
    function submitformhandler(event) {
        event.preventDefault();

        fetch('https://api.evci.in/rest/v1/user/add', {
            method: 'POST',
            body: JSON.stringify({
                name:name,
                email:email
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
            })
            .then(function(response){ 
            return response.json()})
            .then(function(data)
            {  if(data.ack==0){
            
                window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
              }
        
                console.log(data)  
          }).catch(error => console.error('Error:', error)); 
        props.setAddUserClicked(false)

    }
    return (
        <div className="CreateSite-modalBackground">
            <div className="CreateSite-modalContainer row">
                <div className="CreateSite-titleCloseBtn">
                    <button
                        onClick={() => {
                            props.setAddUserClicked(false)
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h3>Invite new User</h3>
                </div>
                <div className="Create-sitebody">
                    <form className='create-form' onSubmit={submitformhandler}>
                        <label className='create-modal-label'>Enter User Name:</label>
                        <input type='text' className='create-site-name' value={name} onChange={setnamehandler} />

                        <br>
                        </br>
                        <label className='create-modal-label'>Enter Email Id:</label>
                        <input className='create-site-name' type="email" value={email} onChange={setemailhandler} />
                        <br>
                        </br>
           
                        <button className='save--button'>Save</button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default AdminaddModal