import React from 'react'
import stationlogo from './../../assests/red-location-logo.png';
import './index.css'
function MenuHelper(props){
    return(
    <div className='Menu--card'>
        <div className='card'>
         <img className='card--img' src={stationlogo} alt="building--logo"/>
         <h6 className='card--heading'>{props.place}</h6>
        </div>
        <h4 className='Menu-card--data'>{props.value}</h4>
    </div>
    )
}

export default MenuHelper