import React from 'react'
import simplebuildingLogo from './../../assests/green-building-logo.png';
import bluebuildingLogo from './../../assests/red-location-logo.png';
import './index.css'
import {useNavigate} from 'react-router-dom';

function Dashboardformhelper(props){
    const navigate = useNavigate();
    

    function clickhandler(e){
        console.log('clicked')
        props.selectidhandler()
        navigate('/dashsitepage')
    }


    return(
        <div className='dashboard-city-card' onClick={clickhandler}>
        <img src={bluebuildingLogo} alt="cityName" className='city--image'/>
        <h4 className='cityName'>{props.sitenamelabel} {props.mysid}</h4>
       </div>
    )
}
export default Dashboardformhelper