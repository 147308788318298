import React from 'react'
import './index.css'
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import erroricon from './../../assests/error_icon.png';
import ok_tick_icon from './../../assests/ok_tick_icon.png';
import runninggif from './../../assests/spinner2.gif';
import deleteicon from './../../assests/delete.png';
import authcontext  from '../../Store/auth-context';

function Dashsiteinformationcard(props) {
    const navigate = useNavigate()
    const ctx = React.useContext(authcontext)
    function clickhandler() {

        if(props.name==="success"){
            navigate('/dashsitepage/Analysisdisplay', { state: props.datastored });
        }else if(props.name==="running"){
            alert('Analysis is still in progress. Please refresh the page to see if it has completed. You will be able to see the result once the analysis is complete. It can take upto 30 mins for analysis to complete, based on inputs.')
        }else{
            alert('Analysis gives an error, Try to do it again!!')
        }
        
    }
    let imgScr;
      
    if(props.name==="success"){
        imgScr = ok_tick_icon
    }else if(props.name==="running"){
        imgScr = runninggif
    }else{
        imgScr=erroricon
    }

    function deletehandler(val){
        fetch(`https://api.evci.in/rest/v1/analysis/delete?st=${ctx}&aid=${val}`)
        .then(function(response){ 
        return response.json()})
        .then(function(data)
        {  if(data.ack=='0'){
        
            window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
          }
    
            console.log(data)  
      }).catch(error => console.error('Error:', error));
      window.location.reload(false);
    }

    return (
        <>
            <div className='dashboard-information-show'>
                <div className='dashboard-infomation-data-1' onClick={clickhandler}><span><img className="dashboard-statusimg" src={imgScr} alt="statusicon" /></span><span className='dashboard-text'>{props.task}</span></div>
                <h5 className='dashboard-infomation-data-name'>{props.setName}</h5>
                <h5 className='dashboard-infomation-data'>{props.timehappend}<img onClick={()=>deletehandler(props.datastored.response.aid)} style={{width:"20px",marginLeft:"5px",cursor:"pointer"}} src={deleteicon} alt="deleticon"/></h5>
            </div>
        </>
    )
}

export default Dashsiteinformationcard


{/* <Link to="Analysisdisplay"> */ }