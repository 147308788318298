import React from "react";
import GoogleMapReact from 'google-map-react';
import bluebuildingLogo from './../../assests/red-location-logo.png';

import { fitBounds } from 'google-map-react';

const AnyReactComponent = ({ text }) => <>
  <div className="pin">{text}</div>
  <img style={{width:'40px'}} src={bluebuildingLogo} alt="icon"/>
  <div className="pulse"></div>
</>;


const Marker = props => {
  return <>
    <div className="pin"></div>
    <div className="pulse"></div>
  </>
}

// [
  // 77.473473,
  // 34.0211074,
  // 77.6839609,
  // 34.174654
// ]

export default function Googlemapdisplay(props) {

  // const defaultProps = {
  //   center: {
  //     lat: 20.5937,
  //     lng: 78.9629
  //   },
  //   zoom: 4,
  //   bounds:[77.473473,34.0211074,77.6839609,34.174654]
  // };

 const [mydata,setmydata] = React.useState(props.boundingbox)
 console.log(mydata)

  const bounds = {
    nw: mydata.nw,
    se:mydata.se
  };

  const size = {
    width: 1040, // Map width in pixels
    height: 380, // Map height in pixels
  };
  const {center, zoom} = fitBounds(bounds, size);
  // height: '60vh', width: '80%'
  return (
    // Important! Always set the container height explicitly
    <div className="Googlemap-display-intial" style={{ height: '60vh', width: '80%', marginLeft: 'auto', marginTop: "10px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDpaaF8c7ArIRCjCQM3rsm0ZAuuR--RAjw" }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
      >

         {
          props.data.map(obj=>{
            return <AnyReactComponent lat={obj.Latitude} lng={obj.Longitude} text={obj.Name} />

          })
         }
      </GoogleMapReact>
    </div>
  );
}





