import React from 'react'
import './index.css'
// import { data } from './data.js'
import { data2 } from './data2.js'
import Histogramdisplay from '../Histogramdisplay';
import Dendogramdisplay from '../Dendogramdisplay';
import Googlemapdisplay from '../Googlemapdisplay';
import OldGooglemapdisplay from '../OldGooglemapdisplay';
import { useLocation, useNavigate } from 'react-router-dom';

import downloadpng from './../../assests/White-download-arrow.png';
import authcontext from '../../Store/auth-context';

function Analysisdisplay(props) {
    const location = useLocation();
    console.log("mypage")
    console.log(location.state)
    const data = location.state.response
    console.log(data)
    const navigate = useNavigate();

    const ctx = React.useContext(authcontext)

    // React.useEffect(()=>{
    //     //    props.setinputhandler(location.state.data)
    //     navigate('dashsitepage/Analysisdisplay',{state:{inputdata:location}});
    // },[])

    function DeleteAnalysishandler() {
        fetch(`https://api.evci.in/rest/v1/analysis/delete?st=${ctx}&aid=${location.state.aid}`)
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if (data.ack == '0') {

                    window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                }

                console.log(data)
            }).catch(error => console.error('Error:', error));
        navigate('/dashsitepage');
        window.location.reload(false);
    }


    function downloadclickhandler(val) {
        fetch(`https://api.evci.in/rest/v1/analysis/download-response?aid=${location.state.aid}&fileName=${val}`)
            .then(function (response) {
                return response.blob()
            })
            .then(function (blob) {
                if (blob.type === "application/json") {

                    // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
                    alert('File is not available for the given analysis, Please run new Analysis')
                } else {
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = val
                    alink.click();
                }
                console.log(blob)

            }).catch(error => console.error('Error:', error));
    }

    return (
        <div className='Analysis--display'>
            <div className='Analysis-display-items'>
                <div className='Analysis-display-intial'>
                    <br />
                    <div className='heading-display'>
                        <h1>Initial analysis report</h1>
                        <div className='heading-button-group'>
                            <button style={{ cursor: "pointer" }} className='heading-display-download-button' onClick={() => downloadclickhandler('initial_evci_analysis')}><img style={{ margin: "10px" }} src={downloadpng} alt="download-icon" />Download Report</button>
                            <button className='heading-extra' onClick={DeleteAnalysishandler}>Delete Analysis</button>
                        </div>
                    </div>

                    <br />
                    <div class="col-xl-11 col-lg-11">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="card2">
                                    <div class="card-body">
                                        <div class="float-end">
                                            <i class="mdi mdi-account-multiple widget-icon"></i>
                                        </div>
                                        <h5 class="text-muted fw-normal mt-0" title="Number of Sites">Sites</h5>
                                        <h3 class="mt-3 mb-3">{data.res_json.initial_analysis.no_site}</h3>
                                        <p class="mb-0 text-muted">
                                            <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i>{data.res_json.initial_analysis.confirmed_utilization}</span>
                                            <span class="text-nowrap">confirmed sites with utilization</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="card2 widget-flat">
                                    <div class="card-body">
                                        <div class="float-end">
                                            <i class="mdi mdi-cart-plus widget-icon"></i>
                                        </div>
                                        <h5 class="text-muted fw-normal mt-0" title="Capex cost">Capex</h5>
                                        <h3 class="mt-3 mb-3">₹{data.res_json.initial_analysis.capex}Cr</h3>
                                        <p class="mb-0 text-muted">
                                            {/* <span class="text-danger me-2"><i class="mdi mdi-arrow-down-bold"></i> 1.08%</span> */}
                                            <span class="text-nowrap">Total capex charges in INR crores</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="card2 widget-flat">
                                    <div class="card-body">
                                        <div class="float-end">
                                            <i class="mdi mdi-currency-usd widget-icon"></i>
                                        </div>
                                        <h5 class="text-muted fw-normal mt-0" title="Opex Cost">Opex</h5>
                                        <h3 class="mt-3 mb-3">₹{data.res_json.initial_analysis.opex}Cr</h3>
                                        <p class="mb-0 text-muted">
                                            {/* <span class="text-danger me-2"><i class="mdi mdi-arrow-down-bold"></i> 7.00%</span> */}
                                            <span class="text-nowrap">Total opex charges in INR crores</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="card2 widget-flat">
                                    <div class="card-body">
                                        <div class="float-end">
                                            <i class="mdi mdi-pulse widget-icon"></i>
                                        </div>
                                        <h5 class="text-muted fw-normal mt-0" title="Margins">Margin</h5>
                                        <h3 class="mt-3 mb-3">₹{data.res_json.initial_analysis.margin}Cr</h3>
                                        <p class="mb-0 text-muted">
                                            {/* <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 4.87%</span> */}
                                            <span class="text-nowrap">Total margin in INR crores</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Number of sites</th>
                            <th scope="col">Total capes charges (INR Crores)</th>
                            <th scope="col">Total opex charges (INR Crores)</th>
                            <th scope="col">Total margin (INR Crores)</th>
                            <th scope="col">Confirmed sites with utilization</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{data.res_json.initial_analysis.no_site}</td>
                            <td>{data.res_json.initial_analysis.capex}</td>
                            <td>{data.res_json.initial_analysis.opex}</td>
                            <td>{data.res_json.initial_analysis.margin}</td>
                            <td>{data.res_json.initial_analysis.confirmed_utilization}</td>
                        </tr>
                    </tbody>
                </table> */}
                </div>

                <br /><br />
                {/* {
                    "map_bound_box" in data.res_json ? <Googlemapdisplay data={data.res_json.intial_df} boundingbox={data.res_json.map_bound_box} />:<OldGooglemapdisplay data={data.res_json.intial_df} />

                } */}

                {
                     "map_bound_box" in data.res_json ? Array.isArray(data.res_json.map_bound_box)===false?<Googlemapdisplay data={data.res_json.intial_df} boundingbox={data.res_json.map_bound_box} />:<OldGooglemapdisplay data={data.res_json.intial_df}/>:<OldGooglemapdisplay data={data.res_json.intial_df}/>
                }

              

                <br /><br />
                <div className='Analysis-display-intial'><h4 className='histogram-label'>Initial utilisation histogram</h4></div>
                <Histogramdisplay data={data.res_json.initial_utilization_hist} width={1000} height={400} />

                <br /><br />
                <div className='Analysis-display-intial'><h4 className='histogram-label'>Initial unserviced histogram</h4></div>
                <Histogramdisplay data={data.res_json.initial_unserviced_hist} width={1000} height={400} />


                {
                    location.state.data.cluster && <>
                        <br />
                        <hr />
                        <br />
                        <div className='Analysis-display-intial'>
                            <div className='heading-display'>
                                <h1>Cluster analysis report</h1>
                                <button style={{ cursor: "pointer" }} className='heading-display-download-button' onClick={() => downloadclickhandler('clustered_evci_analysis')}> <img style={{ margin: "10px" }} src={downloadpng} alt="download-icon" />Download Report</button>
                            </div>
                            <br />
                            <div class="col-xl-11 col-lg-11">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="card2">
                                            <div class="card-body">
                                                <div class="float-end">
                                                    <i class="mdi mdi-account-multiple widget-icon"></i>
                                                </div>
                                                <h5 class="text-muted fw-normal mt-0" title="Number of Sites">Sites</h5>
                                                <h3 class="mt-3 mb-3">{data.res_json.cluster_analysis.no_site}</h3>
                                                <p class="mb-0 text-muted">
                                                    <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i>{data.res_json.cluster_analysis.cluster_candidate}</span>
                                                    <span class="text-nowrap">cluster candidates, final {data.res_json.cluster_analysis.final} sites</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="card2 widget-flat">
                                            <div class="card-body">
                                                <div class="float-end">
                                                    <i class="mdi mdi-cart-plus widget-icon"></i>
                                                </div>
                                                <h5 class="text-muted fw-normal mt-0" title="Capex cost">Capex</h5>
                                                <h3 class="mt-3 mb-3">₹{data.res_json.cluster_analysis.capex}Cr</h3>
                                                <p class="mb-0 text-muted">
                                                    {/* <span class="text-danger me-2"><i class="mdi mdi-arrow-down-bold"></i> 1.08%</span> */}
                                                    <span class="text-nowrap">Total capex charges in INR crores</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="card2 widget-flat">
                                            <div class="card-body">
                                                <div class="float-end">
                                                    <i class="mdi mdi-currency-usd widget-icon"></i>
                                                </div>
                                                <h5 class="text-muted fw-normal mt-0" title="Opex Cost">Opex</h5>
                                                <h3 class="mt-3 mb-3">₹{data.res_json.cluster_analysis.opex}Cr</h3>
                                                <p class="mb-0 text-muted">
                                                    {/* <span class="text-danger me-2"><i class="mdi mdi-arrow-down-bold"></i> 7.00%</span> */}
                                                    <span class="text-nowrap">Total opex charges in INR crores</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="card2 widget-flat">
                                            <div class="card-body">
                                                <div class="float-end">
                                                    <i class="mdi mdi-pulse widget-icon"></i>
                                                </div>
                                                <h5 class="text-muted fw-normal mt-0" title="Margins">Margin</h5>
                                                <h3 class="mt-3 mb-3">₹{data.res_json.cluster_analysis.margin}Cr</h3>
                                                <p class="mb-0 text-muted">
                                                    {/* <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 4.87%</span> */}
                                                    <span class="text-nowrap">Total margin in INR crores</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Number of sites</th>
                            <th scope="col">Total capes charges (INR Crores)</th>
                            <th scope="col">Total opex charges (INR Crores)</th>
                            <th scope="col">Total margin (INR Crores)</th>
                            <th scope="col">Confirmed sites with utilization</th>
                            <th scope="col">Cluster candidates</th>
                            <th scope="col">Final</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{data.res_json.cluster_analysis.no_site}</td>
                            <td>{data.res_json.cluster_analysis.capex}</td>
                            <td>{data.res_json.cluster_analysis.opex}</td>
                            <td>{data.res_json.cluster_analysis.margin}</td>
                            <td>{data.res_json.cluster_analysis.confirmed_utilization}</td>
                            <td>{data.res_json.cluster_analysis.cluster_candidate}</td>
                            <td>{data.res_json.cluster_analysis.final}</td>
                        </tr>
                    </tbody>
                </table> */}
                        </div>

                        <br /><br />
                        <div className='Analysis-display-intial'><h4 className='histogram-label'>Cluster utilisation histogram</h4></div>
                        <Histogramdisplay data={data.res_json.cluster_utilization_hist} width={1000} height={400} />

                        <br /><br />
                        <div className='Analysis-display-intial'><h4 className='histogram-label'>Cluster unserviced histogram</h4></div>
                        <Histogramdisplay data={data.res_json.cluster_unserviced_hist} width={1000} height={400} />
{/* 
                        {
                            "map_bound_box" in data.res_json?<Googlemapdisplay data={data.res_json.cluster_df} boundingbox={data.res_json.map_bound_box} />:<OldGooglemapdisplay data={data.res_json.cluster_df} />

                        } */}

{
                     "map_bound_box" in data.res_json ? Array.isArray(data.res_json.map_bound_box)===false?<Googlemapdisplay data={data.res_json.cluster_df} boundingbox={data.res_json.map_bound_box} />:<OldGooglemapdisplay data={data.res_json.cluster_df}/>:<OldGooglemapdisplay data={data.res_json.cluster_df} />
                }

                    

                    </>


                }

                {/* google map */}
                {/* <Dendogramdisplay width={1000} height={400}/> */}
            </div>
            <br />
        </div>
    )
}

export default Analysisdisplay