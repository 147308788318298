import { useEffect, useMemo, useRef } from "react";
import './index.css'
// import * as d3 from "d3";
import Plot from 'react-plotly.js';
// const MARGIN = { top: 30, right: 30, bottom: 40, left: 50 };
// const BUCKET_NUMBER = 70;
// const BUCKET_PADDING = 1;
import createPlotlyComponent from 'react-plotly.js/factory';



function Histogramdisplay({ width, height, data }) {
    // const axesRef = useRef(null);
    // const boundsWidth = width - MARGIN.right - MARGIN.left;
    // const boundsHeight = height - MARGIN.top - MARGIN.bottom;

    // const xScale = useMemo(() => {
    //     const max = Math.max(...data);
    //     return d3
    //         .scaleLinear()
    //         .domain([0, 1]) // note: limiting to 1000 instead of max here because of extreme values in the dataset
    //         .range([10, boundsWidth]);
    // }, [data, width]);

    // const buckets = useMemo(() => {
    //     const bucketGenerator = d3
    //         .bin()
    //         .value((d) => d)
    //         .domain(xScale.domain())
    //         .thresholds(xScale.ticks(BUCKET_NUMBER));
    //     return bucketGenerator(data);
    // }, [xScale]);

    // const yScale = useMemo(() => {
    //     const max = Math.max(...buckets.map((bucket) => bucket?.length));
    //     return d3.scaleLinear().range([boundsHeight, 0]).domain([0, max]).nice();
    // }, [data, height]);

    // // Render the X axis using d3.js, not react
    // useEffect(() => {
    //     const svgElement = d3.select(axesRef.current);
    //     svgElement.selectAll("*").remove();

    //     const xAxisGenerator = d3.axisBottom(xScale);
    //     svgElement
    //         .append("g")
    //         .attr("transform", "translate(0," + boundsHeight + ")")
    //         .call(xAxisGenerator);

    //     const yAxisGenerator = d3.axisLeft(yScale);
    //     svgElement.append("g").call(yAxisGenerator);
    // }, [xScale, yScale, boundsHeight]);

    // const allRects = buckets.map((bucket, i) => {
    //     return (
    //         <rect
    //             key={i}
    //             fill="#69b3a2"
    //             x={xScale(bucket.x0) + BUCKET_PADDING / 2}
    //             width={xScale(bucket.x1) - xScale(bucket.x0) - BUCKET_PADDING}
    //             y={yScale(bucket.length)}
    //             height={boundsHeight - yScale(bucket.length)}
    //         />
    //     );
    // });
    // const Plot = createPlotlyComponent(Plotly);
    function fill(N, func) {
        var empty = Array.apply(null, Array(N));
        return empty.map(func);
      }
    return (
        // <svg  className="Histogram-display-intial" width={width} height={height}>
        //     <g
        //         width={boundsWidth}
        //         height={boundsHeight}
        //         transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        //     >
        //         {allRects}
        //     </g>
        //     <g
        //         width={boundsWidth}
        //         height={boundsHeight}
        //         ref={axesRef}
        //         transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        //     />
        // </svg>
        // {
        //     type: 'scatter',
        //     mode: 'lines+points',
        //     x: [1, 2, 3],
        //     y: [2, 6, 3],
        //     marker: {color: 'red'}
        //   }
        <Plot style={{marginLeft:"auto"}} className="Histogram-display-intial" data={[{ x:data,type:"histogram",hoverinfo:"y"}]}  layout={{width: width, height: height}} />

    );
}

export default Histogramdisplay